import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../../../../../context";
import { Button, Modal, Row, Tabs, theme } from "antd";
import AddAdvisorInformationTab from "./components/AddAdvisorInformationTab";

import { StyledTable } from "./styles";
import {
  addAdvisor,
  updateAdvisor,
} from "../../../../../../../../utils/request/manager";
import { isOrganizationOneascent } from "../../../../../../../../utils/helper/specialized";

import Close from "../../../../../../../../icon/Close";

const defaultNewAdvisorState = {
  onboardingQuestionnaireType: "wealthManagement",
};

const AddAdvisorModal = ({ open, handleClose, editedAdvisorEmail }) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [newAdvisorData, setNewAdvisorData] = useState({});
  const [marketingMaterials, setMarketingMaterials] = useState([]);

  useEffect(() => {
    if (editedAdvisorEmail) {
      const advisorData = state.managedUserManagers?.find(
        it => it.email === editedAdvisorEmail
      );

      if (advisorData) {
        setNewAdvisorData({
          ...advisorData,
          folders:
            advisorData.userManager?.marketingMaterialFolders?.map(
              folder => folder.name
            ) ?? [],
          onboardingQuestionnaireType:
            advisorData.userManager?.onboardingQuestionnaireType,
          independent: advisorData.userManager?.userType === "Independent",
          agencyName: advisorData.userManager?.firmName,
          brokerDealerName: advisorData.userManager?.brokerDealerName,
          phoneNumber: advisorData.userManager?.personalInfo?.phoneNumber,
        });
      }
    } else {
      setNewAdvisorData({
        ...defaultNewAdvisorState,
        folders: state.marketingMaterialFolders?.map(it => it.name),
      });
    }

    state.marketingMaterialFolders && setMarketingMaterials(getMarketingData());
  }, [open]);

  const handleValueChange = (key, value) => {
    setNewAdvisorData(state => ({
      ...state,
      [key]: value,
    }));
  };

  const handleSave = () => {
    if (!newAdvisorData.firstName || !newAdvisorData.lastName) {
      state.showWarning("You need to add new advisor name.");
      return;
    } else if (
      !String(newAdvisorData.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      state.showWarning("You need to pass valid email.");
      return;
    } else if (
      isOrganizationOneascent(state.organization?.name) &&
      !newAdvisorData.onboardingQuestionnaireType
    ) {
      state.showWarning("You need to choose Onboarding Questionnaire.");
      return;
    }

    state.setKeyValue("loading", true);

    const requestBody = {
      email: newAdvisorData.email,
      firstName: newAdvisorData.firstName,
      lastName: newAdvisorData.lastName,
      phoneNumber: newAdvisorData.phoneNumber ?? "",
      userType: newAdvisorData.independent ? "Independent" : "BrokerDealer",
      brokerDealerName: newAdvisorData.independent
        ? ""
        : (newAdvisorData.brokerDealerName ?? ""),
      firmName: newAdvisorData.independent
        ? (newAdvisorData.agencyName ?? "")
        : "",
      folders: JSON.stringify(newAdvisorData.folders ?? []),
      onboardingQuestionnaireType: isOrganizationOneascent(
        state.organization?.name
      )
        ? newAdvisorData.onboardingQuestionnaireType
        : "",
    };

    const requestFeature = editedAdvisorEmail ? updateAdvisor : addAdvisor;

    requestFeature(requestBody)
      .then(response => {
        state.showSuccess(response.data);
        handleClose();
        state.setAdminData();
        setNewAdvisorData(defaultNewAdvisorState);
      })
      .catch(errorBody => {
        state.setKeyValue("loading", false);
        state.showWarning(errorBody?.response?.data);
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Created",
      dataIndex: "created",
      render: text => dayjs(text).format("MM/DD/YYYY"),
    },
  ];

  const getMarketingData = () =>
    state.marketingMaterialFolders.map((it, index) => ({
      key: index,
      name: it.name,
      created: it.createDateTime.$date,
    }));

  const getSelectedRowKeys = () => {
    const selectedRowKeys = [];

    if (newAdvisorData.folders?.length) {
      marketingMaterials.map((material, index) => {
        if (newAdvisorData.folders.includes(material.name)) {
          selectedRowKeys.push(index);
        }
      });
    }

    return selectedRowKeys;
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      handleValueChange(
        "folders",
        selectedRows.map(it => it.name)
      );
    },
    selectedRowKeys: getSelectedRowKeys(),
  };

  const items = [
    {
      key: "1",
      label: "Information",
      children: (
        <AddAdvisorInformationTab
          newAdvisorData={newAdvisorData}
          handleValueChange={handleValueChange}
          organization={state.organization?.name?.toLowerCase()}
        />
      ),
    },
    {
      key: "2",
      label: "Marketing Access",
      children: (
        <StyledTable
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={columns}
          dataSource={marketingMaterials}
        />
      ),
    },
  ];

  return (
    <Modal
      className={"add-advisor-modal"}
      closeIcon={<Close />}
      footer={
        <Row justify="space-between">
          <Button shape="round" type="text" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            shape="round"
            style={{
              color: token.colorPrimaryButtonText,
              backgroundColor: token.colorPrimaryButton,
            }}
            onClick={handleSave}
            disabled={state.loading}
          >
            Save
          </Button>
        </Row>
      }
      onCancel={handleClose}
      open={open}
      title={editedAdvisorEmail ? "Edit Advisor" : "Add New Advisor"}
      width={507}
      wrapClassName="add-advisor-modal"
    >
      <Tabs defaultActiveKey="1" items={items} />
    </Modal>
  );
};

export default AddAdvisorModal;

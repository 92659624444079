import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  closestCorners,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { Button, Flex, Image, theme } from "antd";
import SortableCard from "../../../SortableCard";
import SortableColumn from "../../../SortableColumn";

import { PICK_PRIORITY_LIMIT } from "../../constant";

import step_final from "../../images/step_final.png";

import { StyleContainer } from "./styles";

const StepFinal = ({ handleComplete, initialState, prioritiesProps }) => {
  const { token } = theme.useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenSM });
  const [indexHidden, setIndexHidden] = useState(false);

  const allSelectedValuesWithInitialOrder = [
    ...prioritiesProps.step1.values,
    ...prioritiesProps.step2.values,
    ...prioritiesProps.step3.values,
    ...prioritiesProps.step4.values,
  ];

  const getDefaultState = () => ({
    root: [
      ...filterSelectedValuesByType("Spending"),
      ...filterSelectedValuesByType("Giving"),
      ...filterSelectedValuesByType("Investing"),
      ...filterSelectedValuesByType("Saving"),
    ],
    picked: [],
  });

  const filterSelectedValuesByType = type =>
    allSelectedValuesWithInitialOrder
      .filter(it => it.type === type)
      .map(it => it.label);

  const [items, setItems] = useState(getDefaultState());
  const [activeId, setActiveId] = useState();

  useEffect(() => {
    if (initialState) {
      setItems({
        picked: initialState.picked,
        root: initialState.root.filter(it => it !== null),
      });
    }
  }, [initialState]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleButtonComplete = () => {
    handleComplete({
      picked: items.picked.filter(it => it !== null),
      prioritiesProps,
      root: items.root.filter(it => it !== null),
    });
    setItems(getDefaultState());
  };

  const findContainer = id => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find(key => items[key].includes(id));
  };

  const handleDragStart = event => {
    setIndexHidden(true);

    const { active } = event;
    const { id } = active;

    setActiveId(id);
  };

  const handleDragOver = event => {
    const { active, over, activatorEvent } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer ||
      overContainer === "root"
    ) {
      return;
    }

    setItems(prev => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      const activeIndex = activeItems.indexOf(id);
      const overIndex = overItems.indexOf(overId);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          activatorEvent.offsetX > over.rect.top + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter(item => item !== active.id),
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  };

  const handleDragEnd = event => {
    setIndexHidden(false);

    const { active = {}, over = {} } = event;
    const { id } = active;
    const { id: overId } = over ?? {};

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer ||
      overContainer === "root"
    ) {
      return;
    }

    const activeIndex = items[activeContainer].indexOf(active.id);
    const overIndex = items[overContainer].indexOf(overId);

    if (activeIndex !== overIndex) {
      setItems(items => ({
        ...items,
        [overContainer]: arrayMove(
          items[overContainer],
          activeIndex,
          overIndex
        ),
      }));
    }

    setActiveId(null);
  };

  const handleUndo = () => {
    setItems(getDefaultState());
  };

  return (
    <StyleContainer gap={24} id={"finalStepContainer"} vertical>
      <Image preview={false} src={step_final} />
      <div className={"header"}>
        <div className={"sub-title"}>Final step</div>
        <div className={"title"}>
          Please select your Top 5 Priorities in order of importance.
        </div>
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <Flex gap={36} justify={"space-between"} vertical={isSmallScreen}>
          <SortableColumn
            containerStyle={{
              display: "flex",
              gap: 10,
              width: 315,
            }}
            deckView
            disabled={items.picked?.length === PICK_PRIORITY_LIMIT}
            id={"root"}
            items={items.root.filter(it => it !== null)}
          />
          <SortableColumn
            containerStyle={{
              background: "#F5F5F5",
              borderRadius: 12,
              boxShadow: "0 4px 4px 0px rgba(0, 0, 0, 0.25) inset",
              boxSizing: "border-box",
              display: "flex",
              gap: 12,
              height: "fit-content",
              minHeight: 308,
              padding: 8,
              width: 332,
            }}
            id={"picked"}
            items={items.picked}
            showIndex={!indexHidden}
          />
        </Flex>
        <DragOverlay>
          {activeId ? <SortableCard label={activeId} /> : null}
        </DragOverlay>
      </DndContext>
      <Flex justify={"space-between"}>
        <Button
          disabled={!items.picked.length}
          onClick={handleUndo}
          shape={"round"}
          size={"large"}
        >
          Undo
        </Button>
        <Button
          disabled={items.picked.length !== PICK_PRIORITY_LIMIT}
          onClick={handleButtonComplete}
          shape={"round"}
          size={"large"}
          style={{ background: "#428916", color: "#fff" }}
        >
          Complete
        </Button>
      </Flex>
    </StyleContainer>
  );
};

export default StepFinal;

import logo_dark from "../image/logo_dark.svg";
import logo_light from "../image/logo_light.svg";

export default {
  adminPieChartColorPrimary: "#F36835",
  adminPieChartColorSecondary: "#BEC8CE",
  adminPieChartColorTertiary: "#354A5F",
  adminPieChartColorRest: "#72C0C7",
  buttonAddUserBackground: "#184073",
  buttonAskPennyBackground: "#9A18D6",
  chartColors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  colorAdminAnalyticValue: "#131523",
  colorPrimary: "#184073",
  colorPrimaryButton: "#428A16",
  colorPrimaryButtonText: "#FFFFFF",
  colorSecondaryButton: "#4897D1",
  colorSecondaryButtonText: "#FFFFFF",
  colorTertiaryButton: "#E7EBEE",
  colorTertiaryButtonText: "#516474",
  comparisonProductsColors: ["#E48361", "#83C390", "#679BCE"],
  logoSrc: logo_dark,
  pageDashboardRightSideSectionHidden: true,
  pageDashboardRightSideSectionBackground: "#E7EBEE",
  pageDashboardRightSideSectionTitleColor: "#5A607F",
  pageDashboardRightSideSectionTitleCopy: "From Newland",
  proposalGuideFontPrimary: "Barlow, sans-serif",
  quarterlyGoalAumColor: "#3B92B7",
  quarterlyGoalClientColor: "#4F8083",
  quarterlyGoalProposalColor: "#215054",
  sidebarBackground: "#184073",
  sidebarIconColor: "#FFFFFF60",
  sidebarIconColorActive: "#FFFFFF",
  sidebarLogo: logo_light,
  tenantColorPrimary: "#4897D1",
};

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, ConfigProvider, Flex, Row, theme } from "antd";
import ReturnsRiskVolatility from "./components/SectionReturnsRiskVolatility";
import SectionAboutMe from "./components/SectionAboutMe";
import SectionBitAboutYou from "./components/SectionBitAboutYou";
import SectionRiskSelection from "./components/SectionRiskSelection";
import SectionSeekToElevate from "../../../../organism/ModalProposalGuideManager/components/SectionSeekToElevate";
import SectionSeekToEliminate from "../../../../organism/ModalProposalGuideManager/components/SectionSeekToEliminate";
import SectionTimeHorizon from "./components/SectionTimeHorizon";

import StylesContainer from "./styles";

import { defaultProposalData } from "./constants";

import {
  convertCurrentAgeRange,
  convertYearToStartWithdrawalsRange,
  getYearToStartWithdrawalsLabel,
} from "../../../../utils/helper/specialized";

import { ReactComponent as OneAscentSlogan } from "../../image/slogan_dark.svg";
import { ReactComponent as OneAscentLogo } from "../../image/logo_legacy.svg";

const QuestionnaireContentIPQ = ({
  handleCreateProposal,
  handleIpqSaveContinueButton,
  handleIpqSaveExitButton,
  loading,
  newIpqMode,
  onClose,
  open,
  setKeyValue,
  setShowDisclosuresModal,
  showError,
  showWarning,
  userData,
}) => {
  const { token } = theme.useToken();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [proposalData, setProposalData] = useState(defaultProposalData);

  useEffect(() => {
    if (location.state?.stepIndex !== undefined) {
      location.state.stepIndex !== -1 &&
        setCurrentStepIndex(location.state.stepIndex);
    }
  }, [location]);

  useEffect(() => {
    if (userData && !newIpqMode) {
      if (
        userData.valueMap?.prospectObjective ??
        userData.preferences?.valueMap?.prospectObjective
      ) {
        setProposalData(
          userData.valueMap?.prospectObjective ??
            userData.preferences?.valueMap?.prospectObjective ??
            {}
        );
      } else {
        setCurrentStepIndex(0);
        setProposalData(defaultProposalData);
      }
    } else {
      newIpqMode && setKeyValue("newIpqMode", false);
      setCurrentStepIndex(0);
      setProposalData(defaultProposalData);
    }

    navigate(location.pathname, {
      state: { stepIndex: 0 },
    });
  }, [open]);

  const updateProposalData = (key, value) =>
    setProposalData(state => ({
      ...state,
      [key]: value,
    }));

  const handleBackButton = () => {
    handleGuideStepIndexChange(currentStepIndex - 1);
    navigate(location.pathname, {
      state: { stepIndex: currentStepIndex - 1 },
    });
  };

  const handleGuideStepIndexChange = newStepIndex => {
    if (newStepIndex === -1) {
      onClose();
    } else if (
      newStepIndex === 3 &&
      (proposalData.currentAgeRange === null ||
        proposalData.incomeExpectations === null)
    ) {
      showWarning("All fields are required to be completed.");
    } else if (
      newStepIndex === 4 &&
      (proposalData.beginWithdrawal === null ||
        proposalData.investmentKnowledge === null)
    ) {
      showWarning("All fields are required to be completed.");
    } else if (
      newStepIndex === 5 &&
      (proposalData.decisionInfluence === null ||
        proposalData.volatilityTolerance === null ||
        proposalData.decliningMarketWithholdingPeriod === null)
    ) {
      showWarning("All fields are required to be completed.");
    } else if (newStepIndex === 7) {
      const yearToStartWithdrawals = convertYearToStartWithdrawalsRange(
        proposalData.beginWithdrawal
      );

      if (proposalData.investmentDuration <= yearToStartWithdrawals) {
        showError(
          `You answered that you would start withdrawals in ${getYearToStartWithdrawalsLabel(proposalData.beginWithdrawal)}. However your length of investment is ${proposalData.investmentDuration} years. Please update your length of investment to be greater than the year you begin to take withdrawals.`
        );
        return;
      }

      handleCreateProposal({
        ...proposalData,
        contributions: proposalData.contributions * 12,
        currentAge: convertCurrentAgeRange(proposalData.currentAgeRange),
        investmentsToAlignWith: JSON.stringify(
          proposalData.investmentsToAlignWith
        ),
        investmentsToAvoid: JSON.stringify(proposalData.investmentsToAvoid),
        yearToStartWithdrawals,
        investmentObjective:
          proposalData.riskTolerance === "preservation" ||
          proposalData.riskTolerance === "conservative"
            ? "income"
            : "growth",
      });
      setCurrentStepIndex(0);
    } else {
      setCurrentStepIndex(newStepIndex);
      navigate(location.pathname, { state: { stepIndex: newStepIndex } });
    }
  };

  const handleSaveContinueClick = () => {
    handleIpqSaveContinueButton({
      proposalData,
      callback: () => {
        updateProposalData(
          "investmentAmount",
          defaultProposalData.investmentAmount
        );
        updateProposalData("contributions", defaultProposalData.contributions);
        updateProposalData(
          "investmentDuration",
          defaultProposalData.investmentDuration
        );
        updateProposalData(
          "householdIncome",
          defaultProposalData.householdIncome
        );
        setCurrentStepIndex(currentStepIndex + 1);
      },
    });
  };

  const handleSaveExitClick = () => {
    handleIpqSaveExitButton({
      proposalData,
      callback: () => {
        setCurrentStepIndex(0);
      },
    });
  };

  const ButtonBack = ({ label = "Back" }) => (
    <Button
      onClick={handleBackButton}
      shape={"round"}
      size={"small"}
      style={{ fontSize: 16 }}
      type={"text"}
    >
      {label}
    </Button>
  );

  const ButtonForward = ({ label = "Continue" }) => (
    <Button
      disabled={loading}
      onClick={() => handleGuideStepIndexChange(currentStepIndex + 1)}
      shape={"round"}
      size={"small"}
      style={{
        width: 142,
        fontSize: 16,
        backgroundColor: "#192849",
        color: "#FFFFFF",
      }}
      type={"primary"}
    >
      {label}
    </Button>
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: token.proposalGuideRadio,
          Checkbox: token.proposalGuideCheckbox,
        },
      }}
    >
      <StylesContainer fontFamily={token.proposalGuideFontPrimary}>
        <div className={"content-body"}>
          <Row
            justify="space-between"
            style={{
              borderBottom: "1px solid #6DC1C7",
              marginBottom: 25,
              paddingBottom: 10,
            }}
          >
            <OneAscentLogo style={{ width: 160 }} />
            <OneAscentSlogan style={{ width: 160 }} />
          </Row>
          {currentStepIndex === 0 && (
            <SectionSeekToEliminate
              ButtonsContainer={() => (
                <Flex align={"center"} gap={12} vertical>
                  <ButtonForward />
                  <ButtonBack label={"Close"} />
                </Flex>
              )}
              proposalData={proposalData}
              updateProposalData={updateProposalData}
              openDisclosuresModal={() => setShowDisclosuresModal(true)}
            />
          )}
          {currentStepIndex === 1 && (
            <SectionSeekToElevate
              ButtonsContainer={() => (
                <Flex align={"center"} gap={12} vertical>
                  <ButtonForward />
                  <ButtonBack />
                </Flex>
              )}
              proposalData={proposalData}
              updateProposalData={updateProposalData}
              openDisclosuresModal={() => setShowDisclosuresModal(true)}
            />
          )}
          {currentStepIndex === 2 && (
            <SectionAboutMe
              ButtonsContainer={() => (
                <Flex align={"center"} gap={12} vertical>
                  <ButtonForward />
                  <ButtonBack />
                </Flex>
              )}
              proposalData={proposalData}
              updateProposalData={updateProposalData}
            />
          )}
          {currentStepIndex === 3 && (
            <SectionTimeHorizon
              ButtonsContainer={() => (
                <Flex align={"center"} gap={12} vertical>
                  <ButtonForward />
                  <ButtonBack />
                </Flex>
              )}
              proposalData={proposalData}
              updateProposalData={updateProposalData}
            />
          )}
          {currentStepIndex === 4 && (
            <ReturnsRiskVolatility
              ButtonsContainer={() => (
                <Flex align={"center"} gap={12} vertical>
                  <ButtonForward />
                  <ButtonBack />
                </Flex>
              )}
              proposalData={proposalData}
              updateProposalData={updateProposalData}
            />
          )}
          {currentStepIndex === 5 && (
            <SectionRiskSelection
              ButtonsContainer={() => (
                <Flex
                  align={"center"}
                  gap={22}
                  style={{ marginTop: 100 }}
                  vertical
                >
                  <Button
                    disabled={!proposalData.acceptedRiskLevel || loading}
                    onClick={handleSaveContinueClick}
                    shape={"round"}
                    size={"small"}
                    style={{ background: "#192849", fontSize: 16, wodth: 240 }}
                    type={"primary"}
                  >
                    Save & Continue Planning
                  </Button>
                  <Button
                    disabled={!proposalData.acceptedRiskLevel || loading}
                    onClick={handleSaveExitClick}
                    shape={"round"}
                    size={"small"}
                    style={{
                      fontSize: 16,
                      width: 132,
                    }}
                    type={"primary"}
                  >
                    Save & Exit
                  </Button>
                  <ButtonBack />
                </Flex>
              )}
              proposalData={proposalData}
              updateProposalData={updateProposalData}
            />
          )}
          {currentStepIndex === 6 && (
            <SectionBitAboutYou
              ButtonsContainer={() => (
                <Flex align={"center"} gap={12} vertical>
                  <ButtonForward label={"Create"} />
                  <ButtonBack />
                </Flex>
              )}
              proposalData={proposalData}
              updateProposalData={updateProposalData}
            />
          )}
        </div>
      </StylesContainer>
    </ConfigProvider>
  );
};

export default QuestionnaireContentIPQ;
